import React from "react"
import Header from '../components/header-en'
import { StaticImage } from "gatsby-plugin-image"
//import { Link } from 'gatsby'
import '../styles/404.css'

import NotFoundImg from '../images/404-img.png'

const NotFound = () => {
    return(
        <div>
            <Header />
            <div id = "page-container">
                <StaticImage src = {NotFoundImg} alt = "Not-Found-Img" />
                <div>
                    The content you are trying to access is not ready
                </div>
            </div>       
        </div>

    )
}

export default NotFound;